import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';

import { ToastrUrkuService } from 'src/app/shared/services/toastr.urku.service';

import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertErrorService } from 'src/app/views/errors/alert-error/alert-error.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

    protected urlAuth: string = 'api/auth/signin';

    constructor(private authService: AuthService,
        private alertErrorService: AlertErrorService,
        private toastr: ToastrUrkuService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUser = this.authService.getUser;
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + currentUser.token.key
                }
            });
        }

        //request = request.clone({ headers: request.headers.set('Access-Control-Allow-Origin', '*') });
        //request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            retry(0), // Reintentar
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpErrorResponse) {
                    console.log('map.event--->>>', event);
                }
                return event;
            }),
            catchError((responseError: any/*HttpErrorResponse*/) => {
                console.log('error--->>>', responseError);
                switch (responseError.status) {
                    case 0:
                        this.toastr.error('Error interno del servidor');
                        //this.alertErrorService.addError('No se pudo establecer una conexión con el servidor');
                        break;
                    default:
                        //this.alertErrorService.addError(null);
                        
                        // if (responseError.error instanceof ErrorEvent) {
                        //     this.toastr.error(responseError.error.message);
                        // } else {
                        //     this.toastr.error(responseError.error.message ? responseError.error.message : responseError.error);
                        // }
                        break;
                }
                return throwError(responseError)
            })
        );
    }

}