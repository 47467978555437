import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
// import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';

const adminRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: './views/dashboard/dashboard.module#DashboardModule'
    },
    {
      path: 'configuracion',
      loadChildren: './views/configuracion/configuracion.module#ConfiguracionModule'
    },
    {
      path: 'crianza-seguimiento',
      loadChildren: './views/crianza-seguimiento/crianza-seguimiento.module#CrianzaSeguimientoModule'
    },
    {
      path: 'gestion-documentaria',
      loadChildren: './views/gestion-documentaria/gestion-documentaria.module#GestionDocumentariaModule'
    },
    {
      path: 'finanza',
      loadChildren: './views/finanzas/finanzas.module#FinanzasModule'
    },
    {
      path: 'reporte',
      loadChildren: './views/reporte/reporte.module#ReporteModule'
    },
    {
      path: 'sistema',
      loadChildren: './views/sistema/sistema.module#SistemaModule'
    }
  ];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/v1',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: './views/sessions/sessions.module#SessionsModule'
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: './views/others/others.module#OthersModule'
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
