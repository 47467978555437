
export class Routes {

    // crianza y seguimiento
    clase: string = "api/clase";
    orden: string = "api/orden";
    familia: string = "api/familia";
    genero: string = "api/genero";
    especie: string = "api/especie";
    especimen: string = "api/especimen";
    ocurrencia: string = "api/ocurrencia";
    sexo: string = "api/sexo";
    recinto: string = "api/recinto";
    categoriaOcurrencia: string = "api/categoria-ocurrencia";
    tipoOcurrencia: string = "api/tipo-ocurrencia";

    // Finanzas
    banco: string = "api/banco";
    caja: string = "api/caja";
    modalidadPago: string = "api/modalidad-pago";
    moneda: string = "api/moneda";
    movimientoCaja: string = "api/movimiento-caja";
    partida: string = "api/partida";
    proyecto: string = "api/proyecto";
    tercero: string = "api/tercero";
    tasaCambioMoneda: string = "api/tasa-cambio-moneda";
    tipoComprobante: string = "api/tipo-comprobante";
    tipoMovimientoCaja: string = "api/tipo-movimiento-caja";

    // gestion documentaria
    area: string = "api/area";
    documento: string = "api/documento";
    tipoDocumento: string = "api/tipo-documento";

    // configuracion
    agencia: string = "api/sucursal";
    empresa: string = "api/empresa";
    lenguaje: string = "api/lenguaje";
    modulo: string = "api/modulo";
    perfil: string = "api/perfil";
    personal: string = "api/personal";
    submodulo: string = "api/submodulo"
    ubigeo: string = "api/ubigeo";
    usuario: string = "api/usuario";
    turno: string = "api/turno";
    rol: string = "api/rol";

    // Sistema
    sistema: string = "api/sistema";
    //reporte
    reporteFinanza: string = 'api/reporte/finanza';

    constructor() { }
    
}