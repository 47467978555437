import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { BehaviorSubject, Subject } from "rxjs";

import { HttpClientUrku } from './http.urku';
import { Acceso } from 'src/app/model/acceso';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  //Only for demo purpose
  authenticated = false;
  private currentUser = new Subject<Acceso>();
  private urlAuth = 'api/auth';
  private urlLogout = 'api/logout';
  public AUTH_KEY: string = 'currentUser';
  private currentUserSubject: BehaviorSubject<Acceso>;

  constructor(private store: LocalStoreService,
      private _http: HttpClientUrku
    ) {
      //this.checkAuth();
  }

  checkAuth() {
    this.currentUserSubject = new BehaviorSubject<Acceso>(JSON.parse(this.store.getItem(this.AUTH_KEY)));
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  public get getUser(): Acceso {
    this.checkAuth();
    return this.currentUserSubject.value;
    //return of({});
  }

  // public getUserSubject = () => {
  //   this.currentUser.asObservable();
  // }

  // public setUserSubject = (): void =>{
  //   this.checkAuth();
  //   this.currentUser.next(this.currentUserSubject.value);
  // }

  /*signin(credentials) {
    this.authenticated = true;
    this.store.setItem("demo_login_status", true);
    return of({}).pipe(delay(1500));
  }*/

  authenticate(user: string, pass: string): Promise<any> {
    return new Promise<Acceso>((resolve, reject) => {
      this._http.post(this.urlAuth, { username: user, password: pass })
      .subscribe((response: Acceso) => {
          //this.sistema = response;
          resolve(response);
      }, (err) => {
        reject(err);
      });
    });
    /*return this.http.post(this.urlAuth, { username: user, password: pass })
    .pipe(map((response:any) => {
        console.log(response);

        // login successful if there's a jwt token in the response
        if (response.acceso && response.acceso.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(acceso));
            this.currentUserSubject.next(acceso);
        }

        if (response) {
            //this.store.setItem(this.AUTH_KEY, JSON.stringify(response.acceso));
            //this.currentUserSubject.next(response.token);
        }
        return response;
    }));*/
  }

  signout(): Promise<any> {
    return new Promise<Acceso>((resolve, reject) => {
      this._http.delete(this.urlLogout, {})
      .subscribe((response: any) => {
          this.authenticated = false;
          this.store.removeItem(this.AUTH_KEY);
          resolve(response);
      }, (err) => {
          this.store.removeItem(this.AUTH_KEY);
          reject(err);
      });
    });
    
  }

}
