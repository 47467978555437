import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LenguajeService } from 'src/app/views/configuracion/lenguaje/lenguaje.service';

@Injectable({
  providedIn: 'root'
})
export class TraduccionService implements TranslateLoader {

    constructor(private lenguajeService: LenguajeService) {
    }

    getTranslation(lang: string): Observable<any> {
        return new Observable<any>((observe) => {
            this.lenguajeService.getByLang(lang).then((lenguaje) => {
                observe.next(JSON.parse(lenguaje.translate));
            });
        });
        
        // if (lang == "es-pe") {
        //     return new Observable<any>((observe) => 
        //         observe.next({
        //             "VIEW_BTN": {
        //                 "GUARDAR": "Guardar",
        //                 "CANCELAR": "Cancelar",
        //                 "SI": "Sí",
        //                 "NO": "No"
        //             },
        //             "VIEW_SELECT" : {
        //                 "ACTIVO": "Activos",
        //                 "INACTIVO": "Inactivos"
        //             },
        //             "MOVIMIENTO_CAJA": {
        //             },
        //             "PERFIL": {
        //                 "NAV": "Perfiles y Permisos",
        //                 "HEADER": "Perfiles y Permisos",
        //                 "BTN": {
        //                     "NUEVO": "Nuevo Perfil",
        //                     "PERMISO": "Asignar Permisos"
        //                 },
        //                 "MODAL": {
        //                     "HEADER" : {
        //                         "NUEVO": "Nuevo Perfil",
        //                         "EDITAR": "Editar Perfil",
        //                         "ANULAR": "Anular"
        //                     }
        //                 },
        //                 "DESCRIPCION": "Descripción"
        //             }
        //         })
        //     );
        // } else {
        //     return new Observable<any>((observe) => 
        //         observe.next({
        //             "VIEW_BTN": {
        //                 "GUARDAR": "Save",
        //                 "CANCELAR": "Cancel",
        //                 "SI": "Yes",
        //                 "NO": "No"
        //             },
        //             "VIEW_SELECT" : {
        //                 "ACTIVO": "Actives",
        //                 "INACTIVO": "Inactives"
        //             },
        //             "MOVIMIENTO_CAJA": {
        //             },
        //             "PERFIL": {
        //                 "NAV": "Profiles and permits",
        //                 "HEADER": "Profiles and permits",
        //                 "BTN": {
        //                     "NUEVO": "New Profile",
        //                     "PERMISO": "Assign Permissions"
        //                 },
        //                 "MODAL": {
        //                     "HEADER" : {
        //                         "NUEVO": "New Profile",
        //                         "EDITAR": "Edit Profile",
        //                         "ANULAR": "Cancel"
        //                     }
        //                 },
        //                 "DESCRIPCION": "Description"
        //             }
        //         })
        //     );
        // }
        
        // throw new Error("Method not implemented.");
    }
}