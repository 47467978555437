import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from 'src/app/shared/services/auth.service';

import { Acceso } from 'src/app/model/acceso';
import { Modulo } from 'src/app/model/modulo';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;

    translate?: string; // Lenguaje
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;

    translate?: string; // Lenguaje
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    public acceso: Acceso;
    // private ngUserUnsubscribe = new Subject();

    constructor(private auth: AuthService) {
        // this.auth.getUserSubject()
        //     .pipe(takeUntil(this.ngUserUnsubscribe))
        //     .subscribe((acceso: Acceso) => {
        //         console.log(acceso)
        //         //this.displayErrorRef.error = errors
        //         this.acceso = acceso;
        //         if (this.acceso) {
        //             this.setNavegacion(this.acceso.lsModulo);
        //         }
        // });
        this.acceso = this.auth.getUser;
        //console.log(this.acceso.lsModulo);
        if (this.acceso) {
            this.setNavegacion(this.acceso.lsModulo);
        }
    }

    defaultMenu: IMenuItem[] = [];


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'admin':
    //       this.menuItems.next(this.adminMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
    setNavegacion(lsModulo: Modulo[]) {
        this.defaultMenu = [{
            name: 'Dashboard',
            description: '',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/dashboard/v1',
            translate: 'DASHBOARD.DESCRIPCION'
        }]
        let menuItem: IMenuItem;
        let modu1: Modulo;
        let modu2: Modulo;
        let modu3: Modulo;
        let modu4: Modulo;
        lsModulo.forEach(modulo => {
            menuItem = {
                name: modulo.descripcion,
                description: '',
                type: 'dropDown',
                icon: modulo.imagen,
                translate: modulo.translate,
                sub: []
            }
            if (modulo.id == 4) {
                modu4 = modulo;
                let lsItem: IChildItem[] = [];
                let lsCrianza: IChildItem[] = [];
                let lsFinanza: IChildItem[] = [];
                let lsGestion: IChildItem[] = [];
                let lsConfiguracion: IChildItem[] = [];
                modulo.lsSubmodulo.forEach(submodulo => {
                    switch (submodulo.grupo) {
                    case 1:
                        lsCrianza.push({ icon: '', name: submodulo.descripcion, state: modulo.ruta + submodulo.ruta, type: 'link', translate: submodulo.translate });
                        break;
                    case 2:
                        lsFinanza.push({ icon: '', name: submodulo.descripcion, state: modulo.ruta + submodulo.ruta, type: 'link', translate: submodulo.translate });
                        break;
                    case 3:
                        lsGestion.push({ icon: '', name: submodulo.descripcion, state: modulo.ruta + submodulo.ruta, type: 'link', translate: submodulo.translate });
                        break;
                    case 4:
                        lsConfiguracion.push({ icon: '', name: submodulo.descripcion, state: modulo.ruta + submodulo.ruta, type: 'link', translate: submodulo.translate });
                        break;
                    default:
                        lsItem.push({ icon: '', name: submodulo.descripcion, state: modulo.ruta + submodulo.ruta, type: 'link', translate: submodulo.translate });
                        break;
                    }
                });
                if (lsCrianza.length) {
                    menuItem.sub.push({
                        name: modu1.descripcion,
                        type: 'dropDown',
                        // icon: modulo.imagen,
                        translate: modu1.translate,
                        sub: lsCrianza
                    });
                }
                if (lsFinanza.length) {
                    menuItem.sub.push({
                        name: modu2.descripcion,
                        type: 'dropDown',
                        // icon: modulo.imagen,
                        translate: modu2.translate,
                        sub: lsFinanza
                    });
                }
                if (lsGestion.length) {
                    menuItem.sub.push({
                        name: modu3.descripcion,
                        type: 'dropDown',
                        // icon: modulo.imagen,
                        translate: modu3.translate,
                        sub: lsGestion
                    });
                }
                if (lsConfiguracion.length) {
                    menuItem.sub.push({
                        name: modu4.descripcion,
                        type: 'dropDown',
                        // icon: modulo.imagen,
                        translate: modu4.translate,
                        sub: lsConfiguracion
                    });
                }
                menuItem.sub.push(...lsItem);
            } else {
                switch (modulo.id) {
                case 1:
                    modu1 = modulo;
                    break;
                case 2:
                    modu2 = modulo;
                    break;
                case 3:
                    modu3 = modulo;
                    break;
                default:
                    break;
                }
                modulo.lsSubmodulo.forEach(submodulo => {
                    menuItem.sub.push({ icon: '', name: submodulo.descripcion, state: modulo.ruta + submodulo.ruta, type: 'link', translate: submodulo.translate });
                });
            }
            this.defaultMenu.push(menuItem);
        });
        // this.defaultMenu.push({
        //     name: 'Icons',
        //     description: '600+ premium icons',
        //     type: 'link',
        //     icon: 'i-Cloud-Sun',
        //     state: '/icons/iconsmind'
        // });
        // sets iconMenu as default;
        // this.menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
        this.menuItems.next(this.defaultMenu);
        // navigation component has subscribed to this Observable
        this.menuItems$ = this.menuItems.asObservable();
    }

    reloadNavegation() {
        this.setNavegacion(this.acceso.lsModulo);
    }
}
