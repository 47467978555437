import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { Router } from "@angular/router";
import { SearchService } from '../../../../services/search.service';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from 'src/app/shared/services/auth.service';

import { Acceso } from 'src/app/model/acceso';
import { Lenguaje } from 'src/app/model/lenguaje';
import { LenguajeService } from 'src/app/views/configuracion/lenguaje/lenguaje.service';

@Component({
    selector: 'app-header-sidebar-large',
    templateUrl: './header-sidebar-large.component.html',
    styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {

    acceso: Acceso;
    notifications: any[];
    // lsMoneda: Moneda[];
    selectedLenguaje: Lenguaje = {};
    lsLenguaje: Lenguaje[];

    constructor(
        private navService: NavigationService,
        private router: Router,
        public searchService: SearchService,
        public translate: TranslateService,
        private auth: AuthService,
        public lenguajeService: LenguajeService,
    ) {
        this.notifications = [
            /*{
              icon: 'i-Speach-Bubble-6',
              title: 'New message',
              badge: '3',
              text: 'James: Hey! are you busy?',
              time: new Date(),
              status: 'primary',
              link: '/chat'
            }*/
        ];

        this.acceso = this.auth.getUser;
        this.selectedLenguaje = this.acceso.lenguaje;
        if (this.selectedLenguaje) {
           this.translate.setDefaultLang(this.acceso.lenguaje.lang);
        } else {
            this.translate.setDefaultLang('es-pe');
        }
        

        this.lenguajeService.list({limit: -1, estado: true}).then((data) => {
			this.lsLenguaje = data;
        });
    }
  
    ngOnInit() {

        // this.translate.use()
    }
  
    toggelSidebar() {
        const state = this.navService.sidebarState;
        if (state.childnavOpen && state.sidenavOpen) {
            return state.childnavOpen = false;
        }
        if (!state.childnavOpen && state.sidenavOpen) {
            return state.sidenavOpen = false;
        }
        if (!state.sidenavOpen && !state.childnavOpen) {
            state.sidenavOpen = true;
            setTimeout(() => {
                state.childnavOpen = true;
            }, 50);
        }
    }
  
    onChangeLenguaje(lenguaje: Lenguaje) {
        this.translate.use(lenguaje.lang);
        this.selectedLenguaje = lenguaje;
    }

    signout() {
        this.router.navigateByUrl("/auth/logout");
    }

}
