import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { Utils } from '../utils';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NgbDateCustomParserFormatter extends NgbDateAdapter<Date> {

    fromModel(date): NgbDateStruct {
        // console.log("fromModel", date);
        date = moment(date);
        if (!date || !moment.isMoment(date)) {
            return null;
        }
        return Utils.dateToNgbDate(moment(date).toArray());
        // if (value) {
            
        // } else {
        //     return null;
        // }
    }

    toModel(date: NgbDateStruct): Date {
        // console.log("toModel", date);
        // if (date) {
            
        // } else {
        //     return null;
        // }
        return Utils.ngbDateToDate(date);
    }
    
}
// export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {

//     parse(value: string): NgbDateStruct {
//         console.log("parse", value);
//         if (value) {
//             return Utils.dateToNgbDate(new Date(value));
//         } else {
//             return null;
//         }
//     }

//     format(date: NgbDateStruct) {
//         console.log("format", date);
//         if (date) {
//             return Utils.ngbDateToDate({day: date.day, month: date.month, year: date.year}).toString();
//         } else {
//             return null;
//         }
//     }
// }