import { Injectable } from "@angular/core";
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr';

@Injectable({
    providedIn: "root"
})
export class ToastrUrkuService {

    private override: Partial<IndividualConfig> = {
        titleClass: 'text-white',
        messageClass: 'text-white',
    }

    constructor(private toastrService: ToastrService) {
    }

    show(title: string, message?: string, override?: Partial<IndividualConfig>, type?: string): ActiveToast<any> {
        return this.toastrService.show(message, title, override, type);
    };

    success(title?: string, message?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        if (!override) {
            override = this.override;
            override.toastClass = 'toast bg-success';
        }
        return this.toastrService.success(message, title, override);
    };

    error(title: string, message?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        if (!override) {
            override = this.override;
            override.toastClass = 'toast bg-danger';
        }
        return this.toastrService.error(message, title, override);
    };

    info(title: string, message?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        if (!override) {
            override = this.override;
            override.toastClass = 'toast bg-info';
        }
        return this.toastrService.info(message, title, override);
    };

    warning(title: string, message?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
        if (!override) {
            override = this.override;
            override.toastClass = 'toast bg-warning';
        }
        return this.toastrService.warning(message, title, override);
    };
}