import * as moment from "moment";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class Utils {
    static isMobile() {
        return window && window.matchMedia('(max-width: 767px)').matches;
    }
    static dateToNgbDate(dateArray: any[]): NgbDateStruct {
        // if (!value || !moment.isMoment(value)) {
        //     return null;
        // }
        // let date = value.toArray();
        return { year: dateArray[0], month: dateArray[1] + 1, day: dateArray[2] };
    }
    static ngbDateToDate(ngbDate: NgbDateStruct): Date {
        if (!ngbDate) {
            return null;
        }
        // console.log(new Date(Date.UTC(ngbDate.year, ngbDate.month - 1, ngbDate.day)));
        // console.log(new Date(`${ngbDate.month}-${ngbDate.day}-${ngbDate.year}`).toISOString());
        // console.log(new Date(`${ngbDate.month}-${ngbDate.day}-${ngbDate.year}`).toUTCString());
        // console.log(new Date(`${ngbDate.month}-${ngbDate.day}-${ngbDate.year}`).toLocaleString());
        // console.log(moment(`${ngbDate.year}-${ngbDate.month}-${ngbDate.day}`, 'YYYY-MM-DD'));
        return moment(`${ngbDate.year}-${ngbDate.month}-${ngbDate.day}`, 'YYYY-MM-DD').toDate();
    }
    static scrollToTop(selector: string) {
        if (document) {
            const element = <HTMLElement>document.querySelector(selector);
            element.scrollTop = 0;
        }
    }
    static genId() {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
}
