import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertErrorService {

    private errors = new Subject<string>();

    constructor() { }

    public addError = (errors: string): void =>
        this.errors.next(errors);

    public getError = () =>
        this.errors.asObservable();
}
