import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConexionProperties } from 'src/environments/sistema.properties';

@Injectable({ providedIn: 'root' })
export class HttpClientUrku {

    protected uri: string;
    protected httpOptions: any = {
        headers: new HttpHeaders({
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type':  'application/json; charset=UTF-8',
            'Accept': 'application/json'
            //'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
            //'Content-Type':  'text/html; charset=UTF-8'
        }),
        responseType: 'json',
        params: {}
    };

    constructor(private http: HttpClient) {
        // this.uri = ConexionProperties._PROTOCOL +
        //     ConexionProperties._IP + ':' +
        //     ConexionProperties._PORT +'/' +
        //     ConexionProperties._CONTEXT + '/';

            this.uri = ConexionProperties._PROTOCOL +
            ConexionProperties._IP + '/' +
            ConexionProperties._CONTEXT + '/';
    }

    get(url: string, params?: any, options?: HttpOptions): Observable<any> {
        let urlGet = this.uri + url;
        let httpOptions: any;
        if (options) {
            httpOptions = options;
            options.params = params;
        } else {
            httpOptions = this.httpOptions;
            httpOptions.params = params;
        }
        return this.http.get(urlGet, httpOptions);
    }

    post(url: string, params: any, options?: HttpOptions): Observable<any> {
        let urlPost = this.uri + url;
        if (options) {
            this.httpOptions.headers = options.headers;
        }
        // Falta probar
        // if (params) {
        //     const fd = new FormData();
        //     fd.append('data', params);
        // }
        return this.http.post(urlPost, params, this.httpOptions);
    }

    put(url: string, params: any, options?: HttpOptions): Observable<any> {
        let urlPut = this.uri + url;
        if (options) {
            this.httpOptions.headers = options.headers;
        }
        return this.http.put(urlPut, params, this.httpOptions);
    }

    patch(url: string, params: any, options?: HttpOptions): Observable<any> {
        let urlPatch = this.uri + url;
        if (options) {
            this.httpOptions.headers = options.headers;
        }
        if (params) {
            this.httpOptions.params = params;
        } else {
            this.httpOptions.params = {};
        }
        return this.http.patch(urlPatch, params, this.httpOptions);
    }

    delete(url: string, params?: any, options?: HttpOptions): Observable<any> {
        let urlDelete = this.uri + url;
        if (options) {
            this.httpOptions.headers = options.headers;
        }
        if (params) {
            this.httpOptions.params = params;
        }
        return this.http.delete(urlDelete, this.httpOptions);
    }
}

export class HttpOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: string;
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: string;
    withCredentials?: boolean;
}