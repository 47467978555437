import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppInterceptor } from './providers/interceptors/app.interceptor';
import { HttpClientUrku } from './shared/services/http.urku';
import { NgbDateCustomParserFormatter } from './shared/services/ngbdate.formatter.urku';
import { ToastrUrkuService } from 'src/app/shared/services/toastr.urku.service';

// import { LOCALE_DATA } from '@angular/common/src/i18n/locale_data';

import locale_esPE from '@angular/common/locales/es-PE';

registerLocaleData(locale_esPE, 'es-pe');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    NgbModule,
    AppRoutingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: NgbDateAdapter, useClass: NgbDateCustomParserFormatter},
    { provide: LOCALE_ID, useValue: 'es-pe' },
    HttpClientUrku,
    ToastrUrkuService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
