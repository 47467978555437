import { Injectable } from '@angular/core';

import { Routes } from 'src/app/util/routes';
import { HttpClientUrku } from 'src/app/shared/services/http.urku';
import { GridParam } from 'src/app/util/grid.param';

import { Lenguaje } from 'src/app/model/lenguaje';

@Injectable({
    providedIn: 'root'
})
export class LenguajeService {

    private routes: Routes = new Routes();
    private url: string;

    constructor(private _http: HttpClientUrku) {
        this.url = this.routes.lenguaje;
    }

    list(param?: any | GridParam): Promise<Lenguaje[]> {
        return new Promise<Lenguaje[]>((resolve, reject) => {
            this._http.get(this.url, param)
            .subscribe((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    get(id: number): Promise<Lenguaje> {
        return new Promise<Lenguaje>((resolve, reject) => {
            this._http.get(this.url +'/' + id).subscribe((response: Lenguaje) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    form(id):Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (id) {
                this.get(id).then((lenguaje) => {
                    resolve({
                        lenguaje: lenguaje,
                    });
                }).catch((e) => {
                    reject(e);
                })
            } else {
                resolve({
                    lenguaje: new Lenguaje()
                });
            }
        });
    }

    save(lenguaje: Lenguaje, translate: any): Promise<Lenguaje> {
        const formData = new FormData();
        formData.append("lenguaje", JSON.stringify(lenguaje));
        formData.append("translate", JSON.stringify(translate));
        if (!lenguaje.id) {
            return this.add(formData);
        } else {
            return this.edit(lenguaje.id, formData);
        }
    }

    add(data: any): Promise<Lenguaje> {
        return new Promise<Lenguaje>((resolve, reject) => {
            this._http.post(this.url, data, {}).subscribe((response: Lenguaje) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    edit(id: number, data: any): Promise<Lenguaje> {
        return new Promise<Lenguaje>((resolve, reject) => {
            this._http.put(this.url +'/'+ id, data, {}).subscribe((response: Lenguaje) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    estado(lenguaje: Lenguaje): Promise<Lenguaje> {
        if (lenguaje.estado) {
            return this.defuse(lenguaje);
        } else {
            return this.patch(lenguaje);
        }
    }

    defuse(lenguaje: Lenguaje): Promise<Lenguaje> {
        return new Promise<Lenguaje>((resolve, reject) => {
            this._http.delete(this.url +'/'+ lenguaje.id).subscribe((response: Lenguaje) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    patch(lenguaje: Lenguaje, column: string = "estado"): Promise<Lenguaje> {
        return new Promise<Lenguaje>((resolve, reject) => {
            this._http.patch(this.url +'/'+ lenguaje.id+"?column="+column, lenguaje).subscribe((response: Lenguaje) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }

    getByLang(lang: string) {
        return new Promise<Lenguaje>((resolve, reject) => {
            this._http.get(this.url, {lang: lang}).subscribe((response: Lenguaje) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }
}
