import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AlertErrorService } from 'src/app/views/errors/alert-error/alert-error.service';

@Component({
    selector: 'app-admin-layout-sidebar-large',
    templateUrl: './admin-layout-sidebar-large.component.html',
    styleUrls: ['./admin-layout-sidebar-large.component.scss']
})
export class AdminLayoutSidebarLargeComponent implements OnInit {

    moduleLoading: boolean;
    @ViewChild(PerfectScrollbarDirective) perfectScrollbar: PerfectScrollbarDirective;
  
    displayErrorRef: string;
    private ngUnsubscribe = new Subject();
  
    constructor(
        public navService: NavigationService,
        public searchService: SearchService,
        private router: Router,
        private alertErrorService: AlertErrorService
    ) {
        this.initializeErrors();
    }
  
    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
                this.moduleLoading = true;
            }
            if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
                this.moduleLoading = false;
            }
        });
    }

    private initializeErrors() {
        this.alertErrorService
            .getError()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((errors) => {
                console.log(errors)
                //this.displayErrorRef.error = errors
                this.displayErrorRef = errors;
        });
    }
}
